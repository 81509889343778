import React, { useEffect, useState } from "react";
import kingLogo from "../assets/images/KingsTools-logo.svg";
import phoneLogo from "../assets/images/phone-logo.svg";
import mailLogo from "../assets/images/mail-logo.svg";
import searchLogo from "../assets/images/search-icon.svg";
import arrow from '../assets/images/down-arrow.svg';
import tool from '../assets/images/nav-img.svg';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toolImages } from "./toolList";
const Navbar = () => {

  const[ToolOne,ToolTwo,ToolThree,ToolFour]=toolImages;

  const toolsData = {
    "Solid Carbide Drill": [
      { id: "standard", name: "Standard Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"}},
      { id: "coolent", name: "Coolant-Fed Carbide Drills", component: ToolOne,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "specility", name: "Specialty Carbide Drills", component: ToolFour,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "application", name: "Application-Specific Carbide Drills", component: ToolTwo,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "geometry", name: "Geometry-Specific Carbide Drills", component: ToolThree ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"}},
      { id: "high-per", name: "High-Performance Carbide Drills", component: ToolTwo,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
    ],
    "End mill": [
      { id: "stan", name: "Standard Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "spec", name: "Specialty Carbide Drills", component: ToolFour ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "app", name: "Application-Specific Carbide Drills", component: ToolTwo ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "cool", name: "Coolant-Fed Carbide Drills", component: ToolOne ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "geo", name: "Geometry-Specific Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "high", name: "High-Performance Carbide Drills", component: ToolTwo ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },

    ],
    "Micro Drills": [
      { id: "std", name: "Standard Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "colent", name: "Coolant-Fed Carbide Drills", component: ToolOne ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "speci", name: "Specialty Carbide Drills", component: ToolFour ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "appl", name: "Application-Specific Carbide Drills", component: ToolTwo ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "geomet", name: "Geometry-Specific Carbide Drills", component: ToolThree ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "higher", name: "High-Performance Carbide Drills", component: ToolTwo ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
    ],
    "Reamers": [
      { id: "specify", name: "Specialty Carbide Drills", component: ToolFour ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "cation", name: "Application-Specific Carbide Drills", component: ToolTwo ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "stand", name: "Standard Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "c-lent", name: "Coolant-Fed Carbide Drills", component: ToolOne },
      { id: "metry", name: "Geometry-Specific Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "highper", name: "High-Performance Carbide Drills", component: ToolTwo ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
    ],
    "Ball Nose": [
      { id: "stand-dril", name: "Standard Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "cool-drill", name: "Coolant-Fed Carbide Drills", component: ToolOne ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "speci-drill", name: "Specialty Carbide Drills", component: ToolFour ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "applicant-dril", name: "Application-Specific Carbide Drills", component: ToolTwo ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "geo-dril", name: "Geometry-Specific Carbide Drills", component: ToolThree ,data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "high-dril", name: "High-Performance Carbide Drills", component: ToolTwo ,data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
    ],
    "small picco type tools": [
      { id: "specilit-pi", name: "Specialty Carbide Drills", component: ToolFour, data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "application-pi", name: "Application-Specific Carbide Drills", component: ToolTwo, data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "standard-pi", name: "Standard Carbide Drills", component: ToolThree, data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "coolent-pi", name: "Coolant-Fed Carbide Drills", component: ToolOne, data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "geometry-pi", name: "Geometry-Specific Carbide Drills", component: ToolThree, data:{range:"0.1-30 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
      { id: "highper-pi", name: "High-Performance Carbide Drills", component: ToolTwo, data:{range:"0.1-20 mm",length:"Jobber",angle:"118°-140°",coating:"Uncoated/TiN/TiAlN/DLC"} },
    ],
    // Define data for the remaining categories
  };


  const [searchTerm, setSearchTerm] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("Solid Carbide Drill");
  const [toolActive,setToolActive]=useState("standard");
  const location = useLocation();
  const[tools,setTools]=useState(ToolThree)
  const [toolDetails,setToolDetails]=useState(toolsData[selectedCategory]?.[0]?.data)
  const pathname = location.pathname; // This will give you the full path (e.g., '/about')
const navigate=useNavigate();
  // Get the last segment of the path
  const segment = pathname.split("/").pop();
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRead=(e)=>{
    e.preventDefault();
    navigate("/products");
  }

 

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setToolActive(toolsData[category]?.[0]?.id);
  };

  const handleImageChange = (event, toolComponent) => {
    setToolActive(event.target.id);
    setTools(toolComponent.component);
    setToolDetails(toolComponent.data);
    // Trigger tool-specific changes, e.g., image updates
  };




  return (
    <div className="nave">
      <div className="nav-logo">
        <img src={kingLogo} alt="logo" />
      </div>
      <div className="nave-right">
        <div className="nave-phone">
          <img src={phoneLogo} alt="icon" />
          <p>+91 9790978660</p>
        </div>
        <div className="nave-phone">
          <img src={mailLogo} alt="icon" />
          <p>sales@thekingtools.com</p>
        </div>
       

<div className="nave-container">
  {/* Navbar with hamburger menu for small screens */}
  <nav className="navbar navbar-expand-lg">
    {/* Collapse toggle button (hamburger icon) */}
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarMenu"
      aria-controls="navbarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>

    {/* Collapsible menu content */}
    <div className="collapse navbar-collapse" id="navbarMenu">
      <div className="nave-menu">
      <Link to="/" className={segment === "" ? "nave-active" : ""}>
          Home
        </Link>
        <Link to="/about" className={segment === "about" ? "nave-active":""}>
          About Us
        </Link>

        {/* Dropdown for Products */}
        <div className="dropdown">
          <p
            className={`dropdown-toggle ${
              segment === "products" ? "nave-active" : ""
            }`}
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Products <img src={arrow} alt="icon" />
          </p>
          <div
            className="dropdown-menu navproduct-menu"
            aria-labelledby="dropdownMenuButton"
            onClick={(e) => e.stopPropagation()}
          >
            {/* <div className="nave-dropdown-left">
              <ul>
                <li>Solid Carbide Drill</li>
                <li>End mill</li>
                <li>Micro Drills</li>
                <li>Reamers</li>
                <li>Ball Nose</li>
                <li>small picco type tools</li>
              </ul>
            </div>
            <div className="nave-dropdown-center">
              <ul>
                <li id="standard" onClick={(event)=>handleImageChange(event,ToolThree)} className={`${toolActive==="standard"? "nave-active-img":""}`}>Standard Carbide Drills</li>
                <li id="coolent" onClick={(event)=>handleImageChange(event,ToolOne)} className={`${toolActive==="coolent"? "nave-active-img":""}`}>Coolant-Fed Carbide Drills</li>
                <li id="specility" onClick={(event)=>handleImageChange(event,ToolFour)} className={`${toolActive==="specility"? "nave-active-img":""}`}>Specialty Carbide Drills</li>
                <li id="application" onClick={(event)=>handleImageChange(event,ToolTwo)} className={`${toolActive==="application"? "nave-active-img":""}`}>Application-Specific Carbide Drills</li>
                <li id="geomentry" onClick={(event)=>handleImageChange(event,ToolThree)} className={`${toolActive==="geomentry"? "nave-active-img":""}`}>Geometry-Specific Carbide Drills</li>
                <li id="high" onClick={(event)=>handleImageChange(event,ToolOne)} className={`${toolActive==="high"? "nave-active-img":""}`}>High-Performance Carbide Drills</li>
              </ul>
            </div> */}
             {/* <div className="nave-dropdown"> */}
      <div className="nave-dropdown-left">
        <ul>
          {toolsData && Object.keys(toolsData).map((category) => (
            <li key={category} onClick={() => handleCategoryClick(category)}   className={`${selectedCategory === category ? "nave-active-first" : ""}`} >
              {category}
            </li>
          ))}
        </ul>
      </div>
      
      <div className="nave-dropdown-center">
        <ul>
          {selectedCategory &&
            toolsData[selectedCategory].map((tool) => (
              <li
                key={tool.id}
                id={tool.id}
                onClick={(event) => handleImageChange(event, tool)}
                className={`${toolActive === tool.id ? "nave-active-img" : ""}`}
              >
                {tool.name}
              </li>
            ))}
        </ul>
      </div>
    {/* </div> */}
            <div className="nave-dropdown-right">
                <img src={tools} alt="img" />
              
              <div className="nave-dropdown-right-body">
                <div className="nave-right-content" >
                  <p className="nave-right-content-head">Diameter Range</p>
                  <p className="nave-right-content-body">{toolDetails.range}</p>
                </div >
                <div className="nave-right-content" >
                  <p className="nave-right-content-head">Length</p>
                  <p className="nave-right-content-body">{toolDetails.length}</p>
                </div>
                <div className="nave-right-content" >
                  <p className="nave-right-content-head">Point Angle</p>
                  <p className="nave-right-content-body">{toolDetails.angle}</p>
                </div>
                <div className="nave-right-content" >
                  <p className="nave-right-content-head">Coating</p>
                  <p className="nave-right-content-body nave-coating">{toolDetails.coating}</p>
                </div>
              </div>
              <button className="readmore-btn nave-read-btn" onClick={handleRead}>Read More</button>

            </div>
          </div>
        </div>

        <Link to="/contact" className={segment === "contact" ? "nave-active":""}>
          Contact Us
        </Link>
      </div>
    </div>
  </nav>

</div>
        {/* <div className="nave-search nave-phone">
          <img src={searchLogo} alt="icon" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
