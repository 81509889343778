import { HashRouter, Route, Routes } from 'react-router-dom';
import './assets/css/main.css'
import Home from './pages/Home';
import React from 'react';
import About from './pages/About';
import Products from './pages/Products';
import Contact from './pages/Contact';

function App() {
  return (
    <div className="app">
    <HashRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
    </HashRouter>
    </div>
  );
}

export default App;
