import React, { useState } from 'react'
import kingLogo from "../assets/images/KingsTools-logo.svg";
import facebook from '../assets/images/facebook-logo.svg';
import googlee from '../assets/images/google-logo.svg';
import linkedin from '../assets/images/linkedin-logo.svg';
import insta from "../assets/images/insta-logo.svg";
import phoneLogo from "../assets/images/phone-logo.svg";
import mailLogo from "../assets/images/mail-logo.svg";
import local from "../assets/images/location.svg";
import { Link } from 'react-router-dom';
const Footer = () => {
  const [email, setEmail] = useState('');
  return (
    <div className='footer'>
      <div className="foot">
        <div className="foot-left">
            <div className="foot-left-first">
            <img src={kingLogo} alt="image" />
            <p>King Tools formed as an organization based on the strong relationship and healthy trust.</p>
            <div className='foot-subscribe'>
              <p>Subscribe to Our Newsletter</p>
              <div className="foot-subscribe-btns">
              <input 
        type="email" 
        placeholder="Enter Your Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
    />
               <button>Subscribe</button>
              </div>
            </div>
            </div>
            <div className="foot-quick">
                 <h3>Quick Links</h3>
                 <div className="foot-quick-links">
                 <Link to="/">Home</Link>
                <Link to="/about">About Us</Link>
                <Link >Calculators</Link>
                <Link>Catalogs</Link>
                <Link to="/products">Products</Link>
                <Link to="/contact">Contact Us</Link>
                </div>
            </div>
            </div>
            <div className="foot-right">
            
            <div className="foot-contact">
              <h3>Contact Us</h3>
              <div className="foot-contact-details">
                <img src={local} alt="icon" />
                <p>No.56, 1st Cross Street, RajeswariNagar, Karapakkam, Porur,
                Chennai-600116, Tamil Nadu, India</p>
              </div>
              <div className="foot-contact-details">
                <img src={phoneLogo} alt="icon" />
                <p>+91 9790978660</p>

              </div>
              <div className="foot-contact-details">
                <img src={mailLogo} alt="icon" />
                <p>sales@thekingtools.com</p>
              </div>
            </div>
            <div className="foot-follow">
            <h3>Follow Us</h3>
            <div className='foot-social-media'>
                <Link><img src={facebook} alt="icon" /></Link>
                <Link><img src={googlee} alt="icon" /></Link>
                <Link><img src={linkedin} alt="icon" /></Link>
                <Link><img src={insta} alt="icon" /></Link>
            </div>
            </div>
            </div>
      </div>
      <p className="foot-copyright"> <span>&#169;</span>2024 King Tools - All Rights Reserved</p>
    </div>
  )
}

export default Footer