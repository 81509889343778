import React, { useState } from "react";
import shareIcon from "../assets/images/home/share-icon.svg";
import shareIcon1 from "../assets/images/home/facebook-logo.svg";
import shareIcon2 from "../assets/images/home/google-logo.svg";
import shareIcon3 from "../assets/images/home/linkedin-logo.svg";
import shareIcon4 from "../assets/images/home/insta-logo.svg";

const Sharebtn = () => {
    const [shareOpen, setShareOpen] = useState(false);
    const handleShare = () => {
        setShareOpen((prevShareOpen) => !prevShareOpen);
      };
  return (
    <div className="home-head-share">
      <div
        className={`share-icon ${
          shareOpen === true ? "share-icon-active" : ""
        }`}
        onClick={handleShare}
        aria-label="Share"
      >
        <img src={shareIcon} alt="Share Icon" />
      </div>
      <div
        className={`head-share-icons ${
          shareOpen === true ? "head-share-icons-active" : ""
        }`}
      >
        <a href="" target="_blank">
          <img
            src={shareIcon1}
            alt="Share on Facebook"
            aria-label="Share on Facebook"
          />
        </a>
        <a href="" target="_blank">
          <img
            src={shareIcon2}
            alt="Share on Google"
            aria-label="Share on Google"
          />
        </a>
        <a href="" target="_blank">
          <img
            src={shareIcon3}
            alt="Share on LinkedIn"
            aria-label="Share on LinkedIn"
          />
        </a>
        <a href="" target="_blank">
          <img
            src={shareIcon4}
            alt="Share on Instagram"
            aria-label="Share on Instagram"
          />
        </a>
      </div>
    </div>
  );
};

export default Sharebtn;
