

import { Swiper, SwiperSlide } from 'swiper/react';
import '../assets/css/pages/carousel.css'
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';
import 'swiper/css/autoplay';
import { EffectCoverflow,Autoplay, Navigation} from 'swiper/modules';

export default function Carousal({images}) {
  return (
   <div className='carousal-slider'>
      <Swiper
        effect={'coverflow'}
        loop={false} // Disable looping
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 50,
          modifier: 2.5,
          slideShadows: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={false}
        speed={800}
        navigation // Enable default navigation buttons
        modules={[EffectCoverflow, Navigation,Autoplay]}
        className="mySwiper"
        allowTouchMove={false}

      >
       {images?.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
  );
}
