import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ToolList from "../components/toolList";
import Sharebtn from "../components/sharebtn";
import tool1 from "../assets/images/product/tool1.svg";
import aboutTool from "../assets/images/home/about-tool.svg";
import tool3 from "../assets/images/about/about-tool3.svg";

const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const Products = () => {
  return (
    <div className="about">
      <Navbar />
      <section className="section">
        <div className="section-left">
          <h3 className="page-head">Products</h3>
          <h3 className="product-title font-para">Solid Carbide Drills</h3>
          <p className="font-para">
          At King Tool, we specialize in the manufacture of a range of solid
            carbide products, including Drills,End Mills, Hole Mill, Reamers,
            Form Tools, Small Picco Type Tools and more. Our products are made
            their quality and performance.
          </p>
          <button
            className="readmore-btn section-btn"
            onClick={() => scrollToSection("newProduct")}
          >
            Read More
          </button>
        </div>
        <div className="section-right">
          <img src={tool1} alt="image" />
        </div>
      </section>
      <div className="about-share">
        <Sharebtn />
      </div>
      <div className="about-details" id="newProduct">
        <section className="section about-section">
          <div className="section-left">
            <h3 className="page-head">Solid Carbide Drills</h3>
            <p className="font-para">
              King Tool's Solid Carbide Drills are Made With Strict Quality
              Control Standards and Use Advanced Manufacturing echniques, Which
              result in a highly consistent and accurate product
            </p>
          </div>
          <div className="section-right">
            <img src={aboutTool} alt="image" />
          </div>
        </section>
        <section className="section-reverse about-section">
          <div className="section-left">
            <p className="font-para">
              Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent Worem ipsum dolor sit amet, consectetur adipiscing elit.
              Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              Class aptent Worem ipsum dolor sit amet, consectetur adipiscing
              elit. Nunc vulputate libero et velit interdumWorem ipsum dolor sit
              amet, consectetur adipiscing elit. Nunc vulputate libero et velit
              interdum, ac aliquet odio mattis. Class aptent Worem ipsum dolor
              sit amet, consectetur adipiscing elit amet, consectetur adipiscing
              elit. Nunc vulputate libero et velit interdum, ac aliquet odio
              mattis. Class aptent Worem ipsum dolor sit amet, consectetur
              adipiscing elit Worem ipsum dolor sit amet, consectetur adipiscing
              elit. Nunc vulputate libero et velit interdum, ac aliquet odio
              mattis. Class aptent Worem ipsum dolor sit amet, consectetur
              adipiscing elit. Nunc vulputate libero et velit interdum, ac
              aliquet odio mattis. Class aptent Worem ipsum dolor sit amet,
              consectetur adipiscing elit. Nunc vulputate libero et velit
              interdumWore.Class aptent Worem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
          </div>
          <div className="section-right">
            <img src={tool3} alt="image" />
          </div>
        </section>
      </div>
      <ToolList />
      <hr />
      <Footer />
    </div>
  );
};

export default Products;
