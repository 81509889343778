import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sharebtn from "../components/sharebtn";
import tool3 from "../assets/images/about/about-tool3.svg";

import phoneLogo from "../assets/images/about/phone.svg";
import mailLogo from "../assets/images/about/mail.svg";
import locationLogo from "../assets/images/about/location.svg";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [trimmedValue, setTrimmedValue] = useState(null);
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormData({
    //   ...formData,
    //   [name]: value,
    // });
    if (name === "mobile") {
      setTrimmedValue(value.replace(/\D/g, "").slice(0, 10)); // Keep only digits and limit to 10 characters
      setFormData({
        ...formData,
        [name]: trimmedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }

    // Email validation (basic pattern check)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Mobile number validation (assuming 10 digits)
    // const mobilePattern = /^[0-9]{10}$/;
    // if (!trimmedValue.trim()) {
    //   newErrors.mobile = "Mobile number is required.";
    // } else if (!mobilePattern.test(formData.mobile)) {
    //   newErrors.mobile = "Mobile number must be 10 digits.";
    // }

    // Message validation
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    }

    setErrors(newErrors);
    // If no errors, the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      console.log("Form submitted:", formData);
      setFormData({
        name: "",
        email: "",
        mobile: "",
        message: "",
      });
      setTrimmedValue("");
    } else {
      console.log("Form validation failed.");
    }
  };
  return (
    <div className="contact">
      <Navbar />
      <section className="section">
        <div className="section-left contact-section-head">
          <h3 className="page-head">Contact Us</h3>
          <p className="font-para">
          For any enquires, please contact our team<br />
         You can reach the our sales team via the following contact form, by phone or direct visit.
          </p>
        </div>
        <div className="section-right">
          <img src={tool3} alt="image" />
        </div>
      </section>
      <div className="about-share">
        <Sharebtn />
      </div>
      <div className="contact-section">
        <div className="contact-left">
          <div className="contact-details">
            <div className="contact-card">
              <div className="contact-card-head">
                <h3>Let’s get in touch</h3>
                <p className="font-para">Hi there! How can we help you?</p>
              </div>
              <div className="contact-visit">
                <p className="font-para">Visit Us</p>
                <div className="contact-address">
                  <img src={locationLogo} alt="icon" />
                  <p>
                    No.56, 1st Cross Street, RajeswariNagar,
                    <br /> Karapakkam, Porur,
                    <br />
                    Chennai-600116, Tamil Nadu, India
                  </p>
                </div>
              </div>
              <div className="contact-visit">
                <p className="font-para">Chat to Us</p>
                <div className="contact-address">
                  <img src={mailLogo} alt="icon" />
                  <p>sales@thekingtools.com</p>
                </div>
              </div>
              <div className="contact-visit">
                <p className="font-para">Call Us</p>
                <div className="contact-address">
                  <img src={phoneLogo} alt="icon" />
                  <p>+91 9790978660</p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d811.2546987245064!2d80.15888419939738!3d13.038007221082427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261195561064b%3A0x26037fbcf58f2849!2s56%2C%201st%20Cross%20St%2C%20Karambakkam%2C%20Rajeswari%20Nagar%2C%20Porur%2C%20Chennai%2C%20Tamil%20Nadu%20600116!5e0!3m2!1sen!2sin!4v1689923329794!5m2!1sen!2sin"
              width="600"
              height="450"
              allowfullscreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="contact-right">
          <form onSubmit={handleSubmit}>
            <div className="contact-form-input">
              <label className="font-para" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter Your Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {errors.name && <p className="errors">{errors.name}</p>}
            </div>

            <div className="contact-form-input">
              <label className="font-para" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Your Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && <p className="errors">{errors.email}</p>}
            </div>

            <div className="contact-form-input">
              <label className="font-para" htmlFor="mobile">
                Mobile No.
              </label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                placeholder="Enter Your Mobile No"
                value={trimmedValue}
                onChange={handleInputChange}
                required
              />
              {errors.mobile && <p className="errors">{errors.mobile}</p>}
            </div>

            <div className="contact-form-input">
              <label className="font-para" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter Your Message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
              {errors.message && <p className="errors">{errors.message}</p>}
            </div>

            <button className="font-para" type="submit">
              Send Your Message
            </button>
          </form>
        </div>
      </div>
      <hr />
      <Footer />
    </div>
  );
};

export default Contact;
