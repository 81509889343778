import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ToolList from "../components/toolList";
import Carousal from "../components/carousel";
import { useNavigate } from "react-router-dom";
import Sharebtn from "../components/sharebtn";

// images
import tool1 from "../assets/images/home/tool1.svg";
import tool2 from "../assets/images/home/tool2.svg";
import tool3 from "../assets/images/home/tool3.svg";
import tool4 from "../assets/images/home/tool4.svg";
import tool5 from "../assets/images/home/tool5.svg";
import aboutTool from "../assets/images/home/about-tool.svg";

import zooler from "../assets/images/home/zooler.svg";
import quality from "../assets/images/home/quality-tool.svg";
import mission from "../assets/images/home/mission-icon.svg";
import vision from "../assets/images/home/vission-new.svg";
import goal from "../assets/images/home/goal-icon.svg";
import sidearrow from "../assets/images/home/arrow.svg";
import ProductIcon from "../assets/images/home/card1.svg";
import StockIcon from "../assets/images/home/card2.svg";
import CustomerIcon from "../assets/images/home/card3.svg";
import PeoplesIcon from "../assets/images/home/card4.svg";
//  client images
import imGears from "../assets/images/home/clients/im-gears.svg";
import lcb from "../assets/images/home/clients/lcb.svg";
import mitsuba from "../assets/images/home/clients/mitsuba.svg";
import plast from "../assets/images/home/clients/plast.svg";
import mdLogo from "../assets/images/home/clients/md.svg";
import plasticsLogo from "../assets/images/home/clients/plastics.svg";
import titanLogo from "../assets/images/home/clients/titan.svg";
import hiroLogo from "../assets/images/home/clients/hirotec.svg";
import RsmLogo from "../assets/images/home/clients/rsm.svg";
import hblLogo from "../assets/images/home/clients/hbl.svg";
import wiproLogo from "../assets/images/home/clients/wipro.svg";
import royalLogo from "../assets/images/home/clients/royal-Enfield.svg";
import raneLogo from "../assets/images/home/clients/rane.svg";
import atsanLogo from "../assets/images/home/clients/atsan.svg";
import steelLogo from "../assets/images/home/clients/steel.svg";
import liftsLogo from "../assets/images/home/clients/lifts.svg";
import hardware from "../assets/images/home/hardware-tool.mp4";
const Home = () => {
  const navigate = useNavigate();
  const toolImages = [tool1, tool2, tool3, tool4, tool5];
  const allImages = [
    imGears,
    lcb,
    mitsuba,
    plast,
    mdLogo,
    plasticsLogo,
    titanLogo,
    hiroLogo,
    RsmLogo,
    hblLogo,
    wiproLogo,
    royalLogo,
    raneLogo,
    atsanLogo,
    steelLogo,
    liftsLogo,
  ];
  const handleExplore = (val) => {
    navigate(val);
  };

  const visionGoals = [
    {
      title: "Mission",
      imgSrc: mission,
      description: `We are committed to provide the Best products and Solutions 
                    to all our customers at the right time at the right price with
                     Zero tolerance.`,
    },
    {
      title: "Vision",
      imgSrc: vision,
      description: `One Stop solutions for all industrial needs by 2030 Hundred 
                    Energetic staff to support the customers by 2030 Thousand Customers 
                    reach out by 2030`,
    },
    {
      title: "Goal",
      imgSrc: goal,
      description: `Offer High Quality products at a competitive price and Customer 
                    delight are the ultimate goals of our company.`,
    },
  ];
  return (
    <div className="home">
      <Navbar />
      <div className="home-head">
        <div className="home-head-left">
          <h3>Welcome to King Tools.</h3>
          <p>
            King Tools is Leading Manufacturer of High Quality Solid Carbide
            Cutting Tools. With its Commitment To Quality and Customer
            Satisfaction, King Tools Has Established Itself as a Trusted
            Provider of Cutting Solutions For a Range of Industries and
            Applications.
          </p>
          <button onClick={() => handleExplore("/products")}>
            Explore Our Products <img src={sidearrow} alt="icon" />
          </button>
        </div>
        <div className="home-head-right">
          <Carousal images={toolImages} />

          <Sharebtn />
        </div>
      </div>
      <section className="section">
        <div className="section-left">
          <h3 className="font-head">About Us</h3>
          <p className="font-para">
            The company's products are made from tungsten carbide, a material
            known for its hardness, strength, and wear resistance, making them
            ideal for demanding machining applications. King Tools offers a wide
            range of cutting tools, including drills, endmills, reamers, ball
            nose endmills, Form tools and small picco type precision tools,
            among others. In addition to its standard product offerings, King
            Tools also provides custom-made cutting tools that are designed and
            manufactured to meet the specific requirements of its customers. The
            company's process of creating drawings of the custom-made tools and
            getting confirmation from the customer before starting production
            helps ensure that the final product meets the customer's
            specifications and exceeds their expectations. King Tools is
            dedicated to providing its customers with cutting tools that are
            reliable, accurate, and versatile. The company's commitment to
            quality and customer satisfaction has made it a preferred choice for
            professionals and DIY enthusiasts who require precision cutting
            solutions.
          </p>
          <button
            className="readmore-btn section-btn"
            onClick={() => handleExplore("/about")}
          >
            Read More
          </button>
        </div>
        <div className="section-right">
          <img src={aboutTool} alt="image" />
        </div>
      </section>

      <ToolList />

      <div className="rate-section">
        <div className="rate_card">
          <img src={ProductIcon} alt="ProductIcon" />
          <div className="card_body">
            <h2>2500+</h2>
            <p>Products</p>
          </div>
        </div>
        <div className="rate_card">
          <img src={StockIcon} alt="ProductIcon" />
          <div className="card_body">
            <h2>50,000+</h2>
            <p>Stock Inventory</p>
          </div>
        </div>
        <div className="rate_card">
          <img src={CustomerIcon} alt="ProductIcon" />
          <div className="card_body">
            <h2>500+</h2>
            <p>Customers</p>
          </div>
        </div>
        <div className="rate_card">
          <img src={PeoplesIcon} alt="ProductIcon" />
          <div className="card_body">
            <h2>2500+</h2>
            <p> Products</p>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="section-left">
          <h3 className="font-head">King Tools - Find The Hardware You Need</h3>
          <p className="font-para">
            It was established on 1st May 2015 and the head office is located in
            Chennai, Tamilnadu, India. As official distributors of Mitsubishi
            Materials – Japan and Chain Headway – Taiwan brands,We happy to
            support all kind of cutting tools solutions as well as holding
            devises , accessories including special tools. We as trading company
            dealing in cutting tools are playing important roles as bring
            between manufacturers and customers and delivery products to end
            users smoothly. Manufacturing Industry is indispensable to economic
            actives. Whatever your tools enquiry is we ready to help. By
            suppling our customers with high quality products, we contribute to
            the development of Automobile, Aerospace, Medical, Small parts,
            Valves, Pump and motor Industries. ZENITH is a Young company that
            our passion and Wisdom to become the best in the field to provide
            precision cutting tools and holding devises
          </p>
        </div>
        <div className="section-right">
          <video width="600" autoPlay loop muted>
            <source src={hardware} type="video/mp4" />
          </video>
        </div>
      </section>

      <section className="section-reverse">
        <div className="section-left">
          <h3 className="font-head">
            Zoller Pom Basic for Accurate Measurements
          </h3>
          <p className="font-para">
            The Zoller Pom Basic inspection machine is a state-of-the-art tool
            that significantly enhances the manufacturing process at King Tools.
            we utilize the Zoller Pom Basic inspection machine to guarantee the
            highest level of accuracy in our measurements. This advanced
            technology allows us to provide detailed and precise measurement
            reports, ensuring the reliability of our tools.
          </p>
        </div>
        <div className="section-quality-right">
          <img src={zooler} alt="image" />
        </div>
      </section>

      <section className="section">
        <div className="section-left">
          <h3 className="font-head">Quality Assurance</h3>
          <p className="font-para">
            Our rigorous inspection process, powered by the Zoller Pom Basic,
            ensures that every tool we manufacture meets our exacting standards.
            Accurate measurements are crucial for delivering the performance and
            reliability our customers expect.
          </p>
        </div>
        <div className="section-quality-right">
          <img src={quality} alt="image" />
        </div>
      </section>

      <div className="vision-goal">
        {visionGoals.map(({ title, imgSrc, description }) => (
          <div className="home-mission" key={title}>
            <div className="mission-head">
              <h3>{title}</h3>
              <img src={imgSrc} alt={title} />
            </div>
            <p className="font-para">{description}</p>
          </div>
        ))}
      </div>
      <div className="clients">
        <div className="client-head">
          <h3 className="font-head">Our Clients</h3>
          <p className="font-para">
            King Tools is a manufacturer that offers custom-made cutting toolsto
            meet the specific requirements of its customers.
          </p>
        </div>

        <div className="seclogo-wrapper">
          <div className="seclogo">
            {allImages.map((src, index) => (
              <img
                src={src}
                alt={`Slide ${index + 1}`}
                className={`slider${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
      <Footer />
    </div>
  );
};

export default Home;
