import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import tool1 from "../assets/images/about/about-tool1.svg";
import aboutTool from "../assets/images/home/about-tool.svg";
import tool3 from "../assets/images/about/about-tool3.svg";
import Sharebtn from "../components/sharebtn";
import ToolList from "../components/toolList";

const About = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="about">
      <Navbar />
      <section className="section">
        <div className="section-left">
          <h3 className="page-head">About Us</h3>
          <p className="font-para">
            King Tools engaged in the production, quality control and
            distribution of solid carbide tools. This involve designing and
            engineering the products, selecting and sourcing high-quality raw
            materials, manufacturing the tools using specialized equipment and
            processes and ensuring that the finished products meet rigorous
            quality standards. king Tools also provides customer support and
            technical assistance to help the customers optimize their use of the
            tools and achieve better results.
          </p>
          <button
            className="readmore-btn section-btn"
            onClick={() => scrollToSection("newAbout")}
          >
            Read More
          </button>
        </div>
        <div className="section-right">
          <img src={tool1} alt="image" />
        </div>
      </section>
      <div className="about-share">
        <Sharebtn />
      </div>
      <div className="about-details">
        <section className="section about-section" id="newAbout">
          <div className="section-left">
            <h3 className="page-head">About Us</h3>
            <p className="font-para">
              The company's products are made from tungsten carbide, a material
              known for its hardness, strength, and wear resistance, making them
              ideal for demanding machining applications. King Tools offers a
              wide range of cutting tools, including drills, endmills, reamers,
              ball nose endmills, Form tools and small picco type precision
              tools, among others. In addition to its standard product
              offerings, King Tools also provides custom-made cutting tools that
              are designed and manufactured to meet the specific requirements of
              its customers. The company's process of creating drawings of the
              custom-made tools and getting confirmation from the customer
              before starting production helps ensure that the final product
              meets the customer's specifications and exceeds their
              expectations. King Tools is dedicated to providing its customers
              with cutting tools that are reliable, accurate, and versatile. The
              company's commitment to quality and customer satisfaction has made
              it a preferred choice for professionals and DIY enthusiasts who
              require precision cutting solutions.
            </p>
          </div>
          <div className="section-right">
            <img src={aboutTool} alt="image" />
          </div>
        </section>
        <section className="section-reverse about-section">
          <div className="section-left">
            <p className="font-para">
              Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent Worem ipsum dolor sit amet, consectetur adipiscing elit.
              Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              Class aptent Worem ipsum dolor sit amet, consectetur adipiscing
              elit. Nunc vulputate libero et velit interdumWorem ipsum dolor sit
              amet, consectetur adipiscing elit. Nunc vulputate libero et velit
              interdum, ac aliquet odio mattis. Class aptent Worem ipsum dolor
              sit amet, consectetur adipiscing elit amet, consectetur adipiscing
              elit. Nunc vulputate libero et velit interdum, ac aliquet odio
              mattis. Class aptent Worem ipsum dolor sit amet, consectetur
              adipiscing elit Worem ipsum dolor sit amet, consectetur adipiscing
              elit. Nunc vulputate libero et velit interdum, ac aliquet odio
              mattis. Class aptent Worem ipsum dolor sit amet, consectetur
              adipiscing elit. Nunc vulputate libero et velit interdum, ac
              aliquet odio mattis. Class aptent Worem ipsum dolor sit amet,
              consectetur adipiscing elit. Nunc vulputate libero et velit
              interdumWore.Class aptent Worem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
          </div>
          <div className="section-right">
            <img src={tool3} alt="image" />
          </div>
        </section>
      </div>
      <ToolList />
      <hr />
      <Footer />
    </div>
  );
};

export default About;
