import React, { useRef, useState } from "react";
import ToolOne from "../assets/images/product/metal5.svg";
import ToolTwo from "../assets/images/product/metal2.svg";
import ToolThree from "../assets/images/product/metal3.svg";
import ToolFour from "../assets/images/product/metal4.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import prevIcon from '../assets/images/home/prev.svg'

export const toolImages = [ToolOne, ToolTwo, ToolThree, ToolFour];
function ToolList() {
  const tools = [
    {
      title: "Ball Nose",
      image: ToolOne,
      description: `The ball nose design of the endmill alows for smooth, 
      curved cuts and contouring, making it a popular choice for three-
      dimensional machining operations.`,
    },
    {
      title: "Small Picco Type Tools",
      image: ToolTwo,
      description: `precision instrument designed for tasks requiring extreme accuracy
         in tight spaces. It features a small profile, perfect for fine machining,
          electronics, and Medical. Made from high-quality materials, it ensures 
          durability and reliable performance, making it an essential tool for 
          detailed, small-scale work.`,
    },
    {
      title: "Solid Carbide Drill",
      image: ToolThree,
      description: `King Tool's Solid Carbide Drills are Made With Strict Quality Control 
      Standards and Use Advanced Manufacturing
      echniques, Which result in a highly consistent and accurate product`,
    },
    {
      title: "End Mill",
      image: ToolFour,
      description: `King Tools end mills are designed with precision and accuracy 
                    in mind and are manufactured using advanced techniques to ansure consistent
                    andreliable performance.`,
    },
    {
      title: "Reamers",
      image: ToolOne,
      description: `Made from high-grade materials, these reamers offer exceptional wear
       resistance and durability. King tools ensure accurate hole dimensions, smooth finishes,
        and minimal tool deflection. The precision cutting edges provide consistent performance,
       even in the most challenging machining conditions.`,
    },
    {
      title: "Micro Drills",
      image: ToolTwo,
      description: `Micro drills are used for drilling holes in a variety of materials across
       a range of industries, including but not limited to watches, jewellery, medical, eyewear,
        aerospace and automotive.The King Tools Solid Carbide Micro Drill is an excellent choice
         for requiring precision, durability, and reliability in challenging drilling applications.`,
    },
    {
      title: "Small Picco Type Tools",
      image: ToolTwo,
      description: `precision instrument designed for tasks requiring extreme accuracy
         in tight spaces. It features a small profile, perfect for fine machining,
          electronics, and Medical. Made from high-quality materials, it ensures 
          durability and reliable performance, making it an essential tool for 
          detailed, small-scale work.`,
    },
    {
      title: "iView",
      image: ToolThree,
      description: `iView is a measuring system that is able to measure the ground
       tool while it is still in the work holding in the grinding machine. The image 
       of the ground tool taken by the iView camera is compared with an ideal overlay
        profile which is generated by the iView software. The tool size is then automatically
         compensated based on the overlay.`,
    },
    {
      title: "ANCA FX5",
      image: ToolFour,
      description: `The ANCA FX5 is a CNC grinding machine that is capable of a variety of 
      grinding applications. It is equipped with a high-resolution camera system, which allows 
      for precise measurement and inspection of the work piece during the grinding process. The 
      camera can be used for measuring the size and shape of the work piece.`
    },
    {
      title: "Special Tools",
      image: ToolFour,
      description: `King Tools' special solid carbide tools are designed and manufactured to meet
       the specific requirements of its customers. These tools are available in a variety of shapes,
        sizes, and designs, and can be used for a range of applications, including drilling, reaming,
         milling, and threading, among others.`
    },
    
  ];

  const [expandedIndex, setExpandedIndex] = useState(2); // State to track the expanded card index

  const handleCardClick = (index) => {
    setExpandedIndex(index); // Toggle expand/collapse
  };

  const swiperWrapperRef = useRef(null);
  const swiperRef = useRef(null); // Create a ref for the Swiper instance

  const itemWidth=150;
  const calculateCustomTranslate = (activeIndex) => {
    
     return -activeIndex*itemWidth;
  };
  
  const onSlideChange = (swiper) => {
    const newTranslate = calculateCustomTranslate(swiper.activeIndex);

  
    // Apply the custom transform to Swiper's wrapper
    if (swiperWrapperRef.current) {
      swiperWrapperRef.current.style.transform = `translate3d(${newTranslate}px, 0, 0)`;
    }
  };
  

  // const onSlideChange = (swiper) => {
  //   // Calculate the index of the next slide, considering the loop
  //   const nextIndex = swiper.activeIndex + 1
  
  //   // Get the next slide element
  //   const nextSlide = swiper.slides[nextIndex];
  
  //   if (nextSlide) {
  //     // Get the width of the next slide
  //     const nextSlideWidth = nextSlide.getBoundingClientRect().width;
  
  //     // Log or use the width as needed
  //     console.log("Next slide width:", nextSlideWidth);
  
  //     // Apply any calculations or adjustments using the next slide's width
  //     const newTranslate = -swiper.activeIndex * nextSlideWidth;
  
  //     if (swiperWrapperRef.current) {
  //       swiperWrapperRef.current.style.transform = `translate3d(${newTranslate}px, 0, 0)`;
  //     }
  //   }
  // };
  
  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.slidePrev) { // Check for the slidePrev function
      swiperRef.current.slidePrev(); // Access the slidePrev method directly
    }
  };
  
  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.slideNext) { // Check for the slideNext function
      swiperRef.current.slideNext(); // Access the slideNext method directly
    }
  };
  


  return (
    <div className="toollists-head">
      <div className="product-section">
        <h2 className="font-head">Our Products </h2>

        <p className="font-para">
          At King Tool, we specialize in the manufacture of a range of solid
          carbide products, including Drills,End Mills, Hole Mill, Reamers, Form
          Tools, Small Picco Type Tools and more. Our products are made their
          quality and performance.
        </p>
      </div>
     

<div className="tool-list">
  <button className="tool-list-prev" onClick={handlePrevClick}><img src={prevIcon} alt="prev" /></button>
  <Swiper
    ref={swiperRef} // Attach the ref to the Swiper
    onSwiper={(swiper) => {
      swiperWrapperRef.current = swiper.wrapperEl; // Access the wrapper element directly
      swiperRef.current = swiper; // Set the swiper instance
    }}
    spaceBetween={20}
    slidesPerView={6}
    slidesPerGroup={1}
    loop={true}
    onSlideChange={onSlideChange}
    breakpoints={{
      530: { slidesPerView: 1 },
      640: { slidesPerView: 2 },
      768: { slidesPerView: 4 },
      980:{ slidesPerView:4},
      1024: { slidesPerView: 6 },
    }}
    navigation={false} // Disable default navigation buttons
  >
    {tools.map((tool, index) => (
      <SwiperSlide
        key={index}
        className={`${expandedIndex === index ? "swipe-expand" : ""}`}
      >
        <div
          className={`tool-card ${
            expandedIndex === index ? "tool-expanded" : "tool-collapsed"
          }`}
          onClick={() => handleCardClick(index)}
        >
          {expandedIndex === index && (
            <div className="tool-card-content">
              <p className="font-para">{tool.description}</p>
              <button className="readmore-btn">Read More</button>
            </div>
          )}
          <div className="image-container">
            <img
              src={tool.image}
              alt={tool.title}
              className="tool-image"
            />
          </div>
          <h2>{tool.title}</h2>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
  <button className="tool-list-next" onClick={handleNextClick}><img src={prevIcon} alt="next" /></button>
</div>
    </div>
  );
}

export default ToolList;




